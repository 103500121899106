<template>
  <div id="sms_voice__card" class="mnotify-card">
    <div class="mnotify-card-header">
      <h3 class="mnotify-card-header-text">
        Voice messages
        <button
          class="__group__action_btn __add_message"
          v-if="messages.length"
          @click="openModal">
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.536 0.0664062H11.472C14.184 0.0664062 16 1.97041 16 4.80241V11.3392C16 14.1632 14.184 16.0664 11.472 16.0664H4.536C1.824 16.0664 0 14.1632 0 11.3392V4.80241C0 1.97041 1.824 0.0664062 4.536 0.0664062ZM4.58669 8.0587C4.58612 8.4428 4.89725 8.75392 5.28192 8.75392L7.29576 8.75392L7.29632 10.7683C7.29576 11.1524 7.61254 11.4692 7.99721 11.4692C8.38244 11.4698 8.69357 11.1587 8.69866 10.7678L8.69357 8.76015L10.7068 8.75958C11.0977 8.75449 11.4089 8.44336 11.4083 8.05813C11.4083 7.67346 11.0915 7.35668 10.7074 7.35725L8.693 7.35668L8.69866 5.3485C8.693 4.95818 8.38187 4.64705 7.99777 4.64761C7.80544 4.64761 7.62385 4.72624 7.4994 4.8507C7.3693 4.9808 7.29632 5.15673 7.29632 5.34906L7.2901 7.35668L5.28248 7.3629C5.08393 7.35668 4.91422 7.43588 4.78977 7.56033C4.65966 7.69044 4.58669 7.86636 4.58669 8.0587Z"
              fill="#D4D8E2" />
          </svg>
          &nbsp; Add Message
        </button>
      </h3>
    </div>
    <div class="mnotify-card-body">
      <div class="__loading_state" v-loading="loading" v-if="loading"></div>
      <div class="" v-else>
        <div class="_sms__voice__inner" v-if="!messages.length">
          <div class="_sms_voice_message">
            <img src="../../../assets/side_voice.svg" alt="message box" />
          </div>
          <div class="__sms_voice__text">
            No birthday voice messages <br />
            have been saved
          </div>
          <el-button type="primary" @click="openModal">
            Upload birthday voice
          </el-button>
        </div>
        <div class="__app__list__container" v-else>
          <ul class="group_list">
            <li
              class="group_list_item"
              v-for="(sms, key) in messages"
              :key="key">
              <div class="__inside_list__wrapper">
                <p class="__sms">{{ sms.title }}</p>
                <p class="group__action">
                  <button
                    @click="deleteSMS(sms, key)"
                    class="__group__action_btn delete-voice-birth"
                    title="Delete Voice Template">
                    <svg
                      width="19"
                      height="21"
                      viewBox="0 0 19 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.3902 3.80299C17.7804 3.80299 18.1052 4.12305 18.1052 4.53031V4.90686C18.1052 5.30421 17.7804 5.63418 17.3902 5.63418H0.767732C0.37658 5.63418 0.0517578 5.30421 0.0517578 4.90686V4.53031C0.0517578 4.12305 0.37658 3.80299 0.767732 3.80299H3.6921C4.28615 3.80299 4.80313 3.38582 4.93677 2.79722L5.08991 2.12142C5.32792 1.20087 6.1112 0.589478 7.00763 0.589478H11.1494C12.036 0.589478 12.8281 1.20087 13.0573 2.07286L13.2212 2.79623C13.3538 3.38582 13.8708 3.80299 14.4659 3.80299H17.3902ZM15.9045 17.5679C16.2098 14.7567 16.7444 8.07796 16.7444 8.01057C16.7639 7.80645 16.6966 7.61322 16.563 7.45765C16.4196 7.31198 16.2381 7.22578 16.0382 7.22578H2.12642C1.92548 7.22578 1.7343 7.31198 1.60164 7.45765C1.46703 7.61322 1.4007 7.80645 1.41045 8.01057C1.41224 8.02295 1.43142 8.25821 1.46349 8.65152C1.60594 10.3987 2.0027 15.2651 2.25908 17.5679C2.44052 19.2643 3.56715 20.3305 5.19906 20.3692C6.45836 20.3979 7.75569 20.4078 9.08229 20.4078C10.3318 20.4078 11.6009 20.3979 12.8992 20.3692C14.5877 20.3404 15.7133 19.293 15.9045 17.5679Z"
                        fill="#D4D8E2" />
                    </svg>
                  </button>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <MessageModal
      :payload="dataPayload"
      :from="'birthday'"
      @saveVoiceTemplate="saveVoiceMessage"
      ref="messageModal"
      @deleteSuccess="saveVoiceMessage" />
  </div>
</template>

<script>
  import $ from "jquery";
  import MessageModal from "../messages/messageModal.vue";
  import store from "@/state/store.js";
  export default {
    name: "birthday-voice",
    components: {
      MessageModal: MessageModal,
    },
    data() {
      return {
        dataPayload: {},
        loading: false,
      };
    },
    mounted() {
      this.fetchBirthDays();
    },
    computed: {
      messages() {
        return this.$store.getters["voice/voiceNotes"];
      },
    },
    methods: {
      showModal() {
        let element = this.$refs.messageModal.$el;
        $(element).modal("show");
      },
      deleteSMS(sms, key) {
        this.dataPayload = {
          currentModal: "delete",
          selectedKey: key,
          deleteType: "voice",
          selectedMessage: sms,
          deleteMessage:
            "Deleting Birthday Voice Message will remove it permenantly.",
        };
        store.dispatch("uistate/addContactFormData", {
          title: "",
          type: "delete",
        });
        this.showModal();
      },
      async fetchBirthDays() {
        //store.dispatch('campaigns/fetchBirthdayTemplate')
        try {
          this.loading = true;
          const { data } = await store.dispatch("voice/getVoiceTemplates");
          this.loading = false;
        } catch (error) {
          this.loading = false;
        }
      },
      saveVoiceMessage() {
        // fetch voice templates then emit
        this.fetchBirthDays();
        this.$emit("refreshTemplates");
      },
      openModal() {
        store.dispatch("uistate/addContactFormData", {
          title: "Create a birthday voice message",
          type: "voiceTemplate",
          isBirthDay: true,
        });
        this.showModal();
      },
    },
  };
</script>
<style scoped lang="scss">
  .__app__list__container {
    height: calc(376px - 76px);
    width: 100%;
    overflow-y: scroll;
  }
  .__loading_state {
    width: 100%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .__group__action_btn {
    border: none !important;
    outline: none !important;
    background-color: transparent !important;
    display: flex;
    justify-content: flex-end;
  }
  .mnotify-card-header-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .__sms {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 159.3%;
    color: #575761;
  }
  .__add_message {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 159.3%;
    text-align: center;
    color: #f7921c;
  }

  .delete-voice-birth:hover svg path {
    cursor: pointer;
    fill: red;
    transition: all 0.4s ease;
  }

  .__add_message:hover svg path {
    fill: #52d140;
    transition: all 0.4s ease;
  }

  .group__action {
    display: flex;
    justify-content: flex-end;
  }
</style>
