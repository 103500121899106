<template>
    <div class="modal fade" id="senderID" tabindex="-1" role="dialog" aria-labelledby="contact" aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered " role="document">
            <div class="modal-content d-flex align-items-center" v-if="success">
                <successMessage :message="success" />
                <button @click="hideModal" class="__close__success__modal">Close</button>
            </div>
            <div class="modal-content" v-if="!success && senderModalState.type == 'delete'">
                <div class="modal-body m-0 p-0">
                    <div class="row justify-content-center align-items-center mx-4">
                        <div class="continue_edit_header pb-2">
                            <div class="_edit_icon text-center">
                                <img src="@/assets/Delete.svg" height="33px" width="31px" alt="bin icon for delete">
                            </div>
                            <h3 class="continue_edit_header_title">
                                {{ senderModalState.title }}
                            </h3>
                        </div>
                    </div>
                    <div class="continue_edit_header" v-if="errors">
                        <Alert :message="errors" :type="type" />
                    </div>
                    <div class="modal__footer__btns" style="margin-left:5em; margin-right:4em;">
                        <el-button @click="deleteSenderID"
                            style="margin-right:1.5em;padding-right:2em; padding-left:2em" :loading="loading"
                            type="danger">
                            Delete
                        </el-button>
                        <el-button style="margin-right:1.5em;padding-right:2em; padding-left:2em" @click="hideModal"
                            type="primary">
                            Cancel
                        </el-button>
                    </div>
                </div>
            </div>
            <div class="modal-content" v-if="!success && senderModalState.type !== 'delete'">
                <div class="modal-header pt-4 pb-2">
                    <h5 class="modal-title " id="exampleModalLabel">
                        <p class="_set_default" v-if="senderModalState.birthdayType == 'sms'">Create a birthday message
                        </p>
                        <p class="_set_default" v-else>Edit birthday message</p>
                    </h5>
                </div>
                <div class="modal-body m-0 p-0">
                    <div class="row justify-content-center" style="padding-right:1em; padding-left:1em;">
                        <div class="col-md-12" v-if="birthdayType == 'sms'">
                            <el-form :model="formData" :rules="rules" ref="ruleForm"
                                @submit.prevent.native="checkEnter">
                                <div class="row justify-content-center">
                                    <div class="col-md-12">
                                        <div class="form_input_container">
                                            <el-form-item label="Title" prop="title">
                                                <el-input type="text" autocomplete="off"
                                                    placeholder="Enter the title of the message"
                                                    v-model="formData.title"></el-input>
                                            </el-form-item>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form_input_container">
                                            <el-form-item style="margin-top:0px;" label="Message" prop="body">
                                                <el-input type="textarea" :rows="7" autocomplete="off" ref="message"
                                                    placeholder="Enter the message content"
                                                    v-model="formData.body"></el-input>
                                            </el-form-item>
                                        </div>
                                    </div>
                                </div>
                                <span v-if="errors">
                                    <Alert :message="errors" :type="type" />
                                </span>
                            </el-form>
                        </div>
                    </div>
                    <div class="modal__footer__btns" v-if="senderModalState.type == 'newSenderID'">
                        <button class="cancel" @click="hideModal">Cancel</button>
                        <el-button v-if="senderModalState.birthdayType == 'sms'" :loading="loading" type="primary"
                            id="__create__btn" @click="submit('ruleForm', 'save')">
                            Save
                        </el-button>
                        <el-button v-if="senderModalState.birthdayType == 'edit'" :loading="loading" type="primary"
                            id="__create__btn" @click="submit('ruleForm', 'edit')">
                            Edit
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import store from '@/state/store.js'
let $ = require('jquery')
export default {
    name: 'create-birth-messag-and-voice',
    props: {
        userData: {
            required: false,
        }
    },
    data() {
        return {
            success: '',
            errors: "",
            loading: false,
            type: 'alert-danger',
            selectSenderID: false,
            formData: {
                title: '',
                body: '',
                type: 3,
                id: '',
                key: ''
            },
            rules: {
                title: [
                    { required: true, message: 'Please message title is required.', trigger: 'change' },
                ],
                body: [
                    { required: true, message: 'Please message body is required', trigger: 'change' },
                ],
            },
            birthdayType: 'sms',

        }
    },
    created() {

    },
    watch: {
        userData: function (value) {
            this.formData.title = value.message.title
            this.formData.body = value.message.body
            this.formData.id = value.id
        }
    },
    computed: {
        senderModalState() {
            return store.getters['senderid/senderModalState']
        },
    },
    methods: {
        handBodyChange(value) {
            this.formData.body = value
        },
        handTitleChange() {
            this.formData.title = value
        },
        hideModal() {
            $('#senderID').modal('hide')
            if (this.success) {
                this.$emit('success')
            }
            this.success = ""
            this.loading = false
            this.errors = ""
        },
        deleteSenderID() {
            this.errors = '';
            this.loading = true
            this.success = ""
            const { id, key } = this.userData.message
            store.dispatch('campaigns/deleteTemplate', { id: id, key: key })
                .then(response => {
                    this.loading = false
                    this.success = response.data.message
                    this.$emit("fetchTemplatesAgain")
                }).catch(err => {
                    this.loading = false
                    if (err.response?.data == undefined) {
                        this.errors = 'Network error try again'
                    }
                    if (err.response && err.response.status === 404) {
                        this.errors = err.response.data.data.error;
                    }
                })
        },
        createSMS() {
            this.errors = '';
            this.loading = true
            this.formData.type = 3
            store.dispatch('campaigns/saveTemplate', this.formData)
                .then(response => {
                    if (response) {
                        this.loading = false
                        this.success = "Birthday messsage created successfully"
                        this.formData = {
                            title: '',
                            body: '',

                        }
                        this.$emit("fetchTemplatesAgain")
                    }
                }).catch(err => {
                    this.loading = false
                    if (err.response?.data == undefined) {
                        this.errors = 'Network error try again'
                    }
                    if (err.response && err.response.status === 404) {
                        if (err.response.data.data.sender_id) {
                            return this.errors = err.response.data.data.sender_id[0]
                        }
                        this.errors = err.response.data.data.error;
                    }
                })
        },
        editSMS() {
            this.errors = '';
            this.loading = true
            this.formData.id = this.userData.message.id
            this.formData.key = this.userData.key
            this.formData.type = 3
            store.dispatch('campaigns/editTemplate', {
                body: this.formData,
                key: this.formData.key
            })
                .then(response => {
                    if (response) {
                        this.loading = false
                        this.success = "Birthday messsage Updated successfully"
                        this.formData = {
                            title: '',
                            body: '',
                        }
                        this.$emit("fetchTemplatesAgain")
                    }
                }).catch(err => {
                    this.loading = false
                    if (err.response?.data == undefined) {
                        this.errors = 'Network error try again'
                    }
                    if (err.response && err.response.status === 404) {
                        if (err.response.data.data.sender_id) {
                            return this.errors = err.response.data.data.sender_id[0]
                        }
                        this.errors = err.response.data.data.error;
                    }
                    else {
                        this.$notify({
                            title: 'Error',
                            message: err.response.data.message,
                            type: 'error'
                        });
                    }
                })
        },
        submit(formName, type) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (type == 'save') {
                        return this.createSMS()

                    }
                    if (type == 'edit') {
                        return this.editSMS()
                    }
                } else {
                    return false;
                }
            });
        },
    }
}
</script>
<style scoped lang="scss">
.__defualt__state {
    width: 100%;
    padding: 5em 0;

    .__defualt__state_form {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}

.my_form_input_container {
    width: 60%;
}

.__create__new__sender_id {
    width: 35%;
    display: flex;
    align-items: center;
    height: inherit;

    .__create__new__sender_id_btn {
        margin-top: 1.5em;
        width: 100%;
        background: #F5F5F5;
        border-radius: 5px;
        height: 47.9px;
        outline: none;
        border: none;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 159.3%;
        text-align: center;
        color: #F7921C;
    }

}

._set_default {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #575761;
}

._create_message {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 159.3%;
    text-align: right;
    color: #484A4F;
}

.modal-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

._edit_icon {
    margin-top: 4em;
    margin-bottom: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.continue_edit_header_title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #575761;
}

.__sender_id_text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: #979797;
}

.__sender_id_text a {
    text-decoration: none;
    color: #F7921C;
}

.modal__footer__btns {
    display: flex;
    margin: 2em 1em;
    justify-content: space-between;
}

.cancel {
    background: #F5F5F5;
    width: 110px;
    height: 40px;
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    color: #9E9EA7;
    outline: none;
    border: none;
}

#__create__btn {
    height: 40px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form_container {
    display: flex;
    justify-content: center;
}
</style>